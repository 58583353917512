import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import Alert from '../Components/Common/Alert';
import Loader from '../Components/Loader';
import RoleView from '../Components/Admin/Roles/View';
import { Context } from '../Context/MenuContext';

const Roles = () => {
  const [roles, setRoles] = useState([]);
  const [levels, setLevels] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  // Get selectedMenu data from Menu Context
  const [, setSelectedMenu, , setSelectedSubMenu] = useContext(Context);
  // Change menu css with peope page is loaded.
  useEffect(() => {
    setSelectedMenu('admin');
    setSelectedSubMenu('roles');
  }, []);
  const getLevels = () => {
    axios.get('/api/role/levels')
      .then((response) => {
        setLevels(response.data);
        setLoaded(true);
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          setErrorMessage(err.message);
          setShowError(true);
          setLoaded(true);
        }
      });
  };

  useEffect(() => {
    axios.get('/api/role')
      .then((response) => {
        setRoles(response.data);
        getLevels();
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          setErrorMessage(err.message);
          setShowError(true);
          setLoaded(true);
        }
      });
  }, []);

  return (
    <div className="row mb-2">
      <div className="col-12">
        <div className="card card-custom mb-4">
          <div className="card-header align-items-center">
            <div className="card-title align-items-center justify-content-between w-100">
              <h3 className="card-label col p-0">
                <div className="d-flex align-items-center justify-content-start">
                  <span>
                    Roles
                  </span>
                </div>
              </h3>
            </div>
          </div>
          <div className="card-body roles-list">
            <>
              {loaded
                ? (
                  <>
                    {showError
                      ? (
                        <Alert
                          message={errorMessage}
                          alertClass="light-danger"
                          onClose={() => setShowError(false)}
                        />
                      ) : (
                        !errorMessage && (
                          <div className="custom-table">
                            <div className="respnsive-custom-table">
                              <div className="div-table" width="100%">
                                <div className="div-thead">
                                  <div className="div-row">
                                    <div className="div-column-th">Code</div>
                                    <div className="div-column-th">Name</div>
                                    <div className="div-column-th">Minimum Training</div>
                                    <div className="div-column-th">Accounts</div>
                                    <div className="div-column-th">Employees</div>
                                    <div className="div-column-th">Action</div>
                                  </div>
                                </div>
                                <div className="div-tbody">
                                  {roles && roles.length
                                    && roles.map((role) => <RoleView key={role.code} role={role} levels={levels} />)}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                  </>
                ) : <Loader />}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roles;
