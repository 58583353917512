import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { navigate } from '@reach/router';
import HeaderWithInfo from '../Components/Common/HeaderWithInfo';
import { Context as MenuContext } from '../Context/MenuContext';
import CreateAzureSubscription from '../Components/AzureSubscription/CreateSubscription';
import SubscriptionSearch from '../Components/AzureSubscription/Search';
import Alert from '../Components/Common/Alert';
import Loader from '../Components/Loader';
import SubscriptionResult from '../Components/AzureSubscription/Results';
import { Context } from '../Context/RequestsContext';
import { saveUserPageVisitHistory } from '../store/actions/peopleAction';
import { Context as PageContext } from '../Context/PageContext';
import NoRecordFound from '../Components/Common/NoRecordFound';
import EditSubscription from '../Components/AzureSubscription/EditSubscription';

const initialState = {
  results: [],
  loaded: true,
  scrolling: false,
  matchCount: 0,
  showingTotal: 0,
  cloudUsageFrameworkScore: {},
  cloudUsageFrameworkV2Score: {},
  count: 0,
  index: 0,
  estimatedMonthCost: 0,
  previousMonthCost: 0,
  currentSearch: {
    tenants: [],
    status: [],
  },
  errorMessage: '',
};

const AzureSubscription = (props) => {
  const { user } = props;
  const [results, setResults] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [scrolling, setScrolling] = useState(false);
  const [matchCount, setMatchCount] = useState(0);
  const [showingTotal, setShowingTotal] = useState(0);
  const [count, setCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [response, setResponse] = useState(false);
  const [currentSearch, setCurrentSearch] = useState({
    tenants: [],
    status: [],
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [showSubscriptionEditModal, setShowSubscriptionEditModal] = useState(false);
  const [subRequestId, setSubRequestId] = useState(null);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [subscriptionName, setSubscriptionName] = useState('');

  const [title, setTitle] = useState('Request an Azure Subscription');

  // Get filter data from Request Context
  const [modelPopup, setModelPopup] = useContext(Context);
  const { showSubscriptionReqModal } = modelPopup;

  // Get selectedMenu data from Menu Context
  const [, setSelectedMenu, , setSelectedSubMenu] = useContext(MenuContext);
  const [pageContextState, setPageContextState] = useContext(PageContext);
  const { subscriptionPage } = pageContextState;

  const dispatch = useDispatch();

  // Get user login details
  useEffect(() => {
    if (subscriptionPage === '') {
      setPageContextState({
        ...pageContextState,
        subscriptionPage: 'subscriptions',
      });
      const apiUrl = `/api/Auth/SaveUserPageVisit/${user.employeeId}/subscriptions`;
      dispatch(saveUserPageVisitHistory(apiUrl));
    }
  }, [subscriptionPage]);

  useEffect(() => {
    setSelectedMenu('Azure');
    setSelectedSubMenu('subscriptionSearch');
  }, []);

  const doSearch = (clear, pgIndex) => {
    const {
      searchType, criteria, tenants, status,
    } = currentSearch;

    if (clear) { setLoaded(false); }
    // search only if criteria or tenants and status is selected
    if (criteria !== undefined) {
      axios
        .get(
          `/api/azuresubscription/search?t=${searchType}&q=${criteria}&e=${tenants}&s=${status}&i=${clear ? 0 : pgIndex}`,
        )
        .then((response) => {
          const result = response.data;
          setResults(clear ? result.items : results.concat(result.items || []));
          setMatchCount(result.matchCount);
          setShowingTotal(result.showingTotal);
          setCount(result.count);
          setPageIndex(result.index);
          setLoaded(true);
          setScrolling(false);
          setResponse(true);
        })
        .catch((err) => {
          if (err.name !== 'AbortError') {
            setErrorMessage(err.message);
            setResponse(true);
          }
        });
    }
  };

  useEffect(() => {
    doSearch(false, pageIndex);
  }, [currentSearch, pageIndex]);

  const parseQueryString = () => {
    const { location } = props;
    const { search } = location;
    return queryString.parse(search);
  };

  const nextPage = () => {
    if (scrolling !== true && matchCount > showingTotal) {
      setScrolling(true);
      setPageIndex(pageIndex + 1);
    }
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (Math.round(scrollTop) + clientHeight === scrollHeight) {
      nextPage();
    }
  };
  const beginEdit = (e, subReqId, subId, subName) => {
    e.preventDefault();
    setShowSubscriptionEditModal(true);
    setSubRequestId(subReqId);
    setSubscriptionName(subName);
    setSubscriptionId(subId);
    setTitle('Edit an Azure Subscription');
  };

  const executeSearch = (searchType, criteria, status, tenants) => {
    if (
      currentSearch.searchType !== searchType
      || currentSearch.criteria !== criteria
      || currentSearch.status.length !== status.length
      || !currentSearch.status.every((e) => status.includes(e))
      || currentSearch.tenants.length !== tenants.length
      || !currentSearch.tenants.every((e) => tenants.includes(e))
    ) {
      setResults([]);
      setLoaded(false);
      setPageIndex(0);
      setCurrentSearch({
        searchType,
        criteria,
        status,
        tenants,
      });
      const stateVar = {
        count,
        currentSearch: {
          searchType,
          criteria,
          status,
          tenants,
        },
        loaded: false,
        pageIndex: 0,
        results: [],
        scrolling,
        showingTotal,
      };
      navigate(
        `/subscriptions?t=${searchType}&q=${criteria}&e=${tenants}&s=${status}`,
        { state: stateVar, replace: true },
      );
    }
  };

  const clearSearch = (status, tenants) => {
    const defaultState = initialState;
    const currentSearchClr = {
      ...defaultState.currentSearch,
      status: status || [],
      tenants: tenants || [],
    };
    setResults([]);
    setLoaded(true);
    setScrolling(false);
    setMatchCount(0);
    setShowingTotal(0);
    setCount(0);
    setCurrentSearch(currentSearchClr);
    setErrorMessage('');
    setResponse(false);
  };

  const onSubscriptionReqModal = (newValue) => {
    setModelPopup({ ...modelPopup, showSubscriptionReqModal: newValue });
  };

  const qs = parseQueryString();

  return (
    <>
      <div className="row">
        <div className="col-12">
          {/* <!-- Begin::Page Content --> */}
          <div className="card card-custom mb-4">
            <div className="card-header align-items-center">
              <div className="card-title align-items-center justify-content-between w-100">
                <HeaderWithInfo headerName="Azure Subscriptions" />
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={onSubscriptionReqModal}
                >
                  <span className="svg-icon svg-light">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      // eslint-disable-next-line react/no-unknown-property
                      t="1551322312294"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      height="1.2em"
                      width="1.2em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <defs />
                      <path d="M474 152m8 0l60 0q8 0 8 8l0 704q0 8-8 8l-60 0q-8 0-8-8l0-704q0-8 8-8Z" />
                      <path d="M168 474m8 0l672 0q8 0 8 8l0 60q0 8-8 8l-672 0q-8 0-8-8l0-60q0-8 8-8Z" />
                    </svg>
                  </span>
                  {' '}
                  Request an Azure Subscription
                </button>
              </div>
            </div>
            <div className="card-body accout-search">
              <div>
                <SubscriptionSearch
                  selectedValue={qs.t}
                  search={qs.q}
                  tenants={qs.e}
                  statuses={qs.s}
                  executeSearch={executeSearch}
                  clearSearch={clearSearch}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        {errorMessage && (
          <div className="col-12">
            <Alert
              message={errorMessage}
              alertClass="light-danger"
              onClose={(val) => setErrorMessage(val)}
            />
          </div>
        )}
      </div>

      {/* <!-- Begin::Search list --> */}
      {loaded ? (
        <>
          {count > 0 && (
            <>
              <div className="row gutter-b">
                <div className="col-12">
                  <div>
                    <h6 className="text-inverse h6">
                      {matchCount > 0 ? (
                        <span>
                          {showingTotal}
                          {' '}
                          of
                          {' '}
                          {matchCount}
                        </span>
                      ) : null}
                    </h6>
                    <SubscriptionResult subscription={results} onScroll={handleScroll} user={user} beginEdit={beginEdit} />
                    {scrolling ? <Loader /> : null}
                  </div>
                </div>
              </div>
            </>
          )}
          {count === 0 && response
            ? (
              <NoRecordFound />
            ) : ''}
        </>
      ) : (
        <Loader />
      )}

      {showSubscriptionReqModal && (
        <>
          <div className="log-out-layer" />
          <CreateAzureSubscription
            saveUrl="/api/AzureSubscription/Save"
            label="Request Subscription"
            onCancel={() => setModelPopup(false)}
            title={title}
            subscriptionId={subscriptionId}
          />
        </>
      )}
      {showSubscriptionEditModal && (
        <>
          <div className="log-out-layer" />
          <EditSubscription
            saveUrl={`/api/AzureSubscription/${subRequestId || ''}`}
            saveVerb="PUT"
            label="Request Edit Subscription"
            onCancel={() => setShowSubscriptionEditModal(false)}
            title={title}
            subscriptionId={subscriptionId}
            subRequestId={subRequestId}
            subscriptionName={subscriptionName}
            user={user}
          />
        </>
      )}
    </>
  );
};

export default AzureSubscription;
