import React from 'react';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsGauge from 'highcharts/modules/solid-gauge';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';

HighchartsMore(Highcharts);
HighchartsGauge(Highcharts);

const Gauge = (props) => {
  const { name, data } = props;

  const numberWithCommas = (x) => {
    x = x.toString();
    const pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x)) {
      x = x.replace(pattern, '$1,$2');
    }
    return x;
  };

  const gaugeOptions = (nameVal, dataVal) => ({
    chart: {
      type: 'solidgauge',
      height: 100,
    },
    title: {
      text: `${numberWithCommas(dataVal.successful)} out of ${numberWithCommas(
        dataVal.total || 1,
      )}`,
      useHTML: true,
      verticalAlign: 'top',
      style: {
        marginTop: '4em',
        fontSize: '8px',
      },
    },
    pane: {
      center: ['50%', '85%'],
      size: '140%',
      startAngle: -90,
      endAngle: 90,
      background: {
        backgroundColor: '#EEE',
        innerRadius: '60%',
        outerRadius: '100%',
        shape: 'arc',
      },
    },
    yAxis: {
      stops: [
        [0.76, '#bd2130'], // red,
        [0.77, '#ffc107'],
        [0.84, '#ffc107'], // orange-yellow
        [0.85, '#17a2b8'],
        [0.92, '#17a2b8'], // blue
        [0.93, '#1e7e34'], // green
      ],
      lineWidth: 0,
      tickWidth: 0,
      minorTickInterval: null,
      tickAmount: 2,
      labels: {
        enabled: false,
      },
      min: 0,
      max: 100,
    },
    plotOptions: {
      solidgauge: {
        dataLabels: {
          y: 5,
          borderWidth: 0,
          useHTML: true,
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: nameVal,
        data: [dataVal.score],
        dataLabels: {
          format:
            '<div style="text-align:center">'
            + '<span style="font-size:15px">{y}</span>'
            + '<span style="font-size:12px;opacity:0.4"> %</span>'
            + '</div>',
        },
        tooltip: {
          valueSuffix: ' %',
        },
      },
    ],
  });

  return (
    <div>
      <figure>
        <HighchartsReact
          highcharts={Highcharts}
          options={gaugeOptions(name, data)}
        />
      </figure>
    </div>
  );
};

Gauge.propTypes = {
  name: PropTypes.string,
  data: PropTypes.shape({
    total: PropTypes.number.isRequired,
    successful: PropTypes.number.isRequired,
    score: PropTypes.number.isRequired,
  }),
};

Gauge.defaultProps = {
  name: 'Compliant',
  data: {
    total: 0,
    successful: 0,
    score: 0,
  },
};

export default Gauge;
