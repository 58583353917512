import { React, useContext } from 'react';
import HeaderWithInfo from '../../Components/Common/HeaderWithInfo';
import TrainingOverridePopup from '../../Components/AzureSubscription/TrainingOverridePopup';
import { Context } from '../../Context/RequestsContext';

const AzureESITrainingOverride = () => {
  const [modelPopup, setModelPopup] = useContext(Context);
  const { showTrainingOverridePopup } = modelPopup;

  const onShowTrainingOverridePopup = (newValue) => {
    setModelPopup({ ...modelPopup, showTrainingOverridePopup: newValue });
  };
  return (
    <>
      <div className="row">
        <div className="col-12">
          {/* <!-- Begin::Page Content --> */}
          <div className="card card-custom mb-4">
            <div className="card-header align-items-center">
              <div className="card-title align-items-center justify-content-between w-100">
                <HeaderWithInfo headerName="Azure ESI Training Override" infoIcon />
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={onShowTrainingOverridePopup}
                >
                  <span className="svg-icon svg-light">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      // eslint-disable-next-line react/no-unknown-property
                      t="1551322312294"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      height="1.2em"
                      width="1.2em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <defs />
                      <path d="M474 152m8 0l60 0q8 0 8 8l0 704q0 8-8 8l-60 0q-8 0-8-8l0-704q0-8 8-8Z" />
                      <path d="M168 474m8 0l672 0q8 0 8 8l0 60q0 8-8 8l-672 0q-8 0-8-8l0-60q0-8 8-8Z" />
                    </svg>
                  </span>
                  {' '}
                  Request Azure ESI Training Override
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showTrainingOverridePopup
        && (
          <>
            <div className="log-out-layer" />
            <TrainingOverridePopup
              label="Submit Request"
              title="Azure ESI Training Override"
              onCancel={() => setModelPopup(false)}
            />
          </>
        )}
    </>
  );
};

export default AzureESITrainingOverride;
