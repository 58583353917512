import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Loader';
import ComplianceAccordion from './ComplianceAccordion';
import Alert from '../../Common/Alert';
import { getAccountCompliance } from '../../../store/actions/accountAction';

const AccountComplianceTab = (props) => {
  const { accountId } = props;
  const [activeNewCloudUsage, setActiveNewCloudUsage] = useState(false);
  const [activeCloudUsageV2, setActiveCloudUsageV2] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrMsg, setShowErrMsg] = useState(false);

  const dispatch = useDispatch();
  const accountData = useSelector((state) => state.accountProfile);
  const { complianceLoading, errCompliance, accountCompliance } = accountData;

  useEffect(() => {
    const apiUrl = `/api/account/${accountId}/compliance`;
    dispatch(getAccountCompliance(apiUrl));
  }, [dispatch]);

  useEffect(() => {
    if (errCompliance !== '') {
      setErrorMessage(errCompliance);
      setShowErrMsg(true);
    }
  }, [errCompliance]);

  function collapseSection(button) {
    switch (button) {
      case 'UpcomingNewRulesForCUF':
        setActiveNewCloudUsage(!activeNewCloudUsage);
        setActiveCloudUsageV2(false);
        break;
      case 'CloudUsageV2':
        setActiveCloudUsageV2(!activeCloudUsageV2);
        setActiveNewCloudUsage(false);
        break;
      default:
        return '';
    }
    return '';
  }

  const viewOptions = {
    newCuf: {
      short: 'UpcomingNewRulesForCUF',
      long: 'Upcoming new rules for CUF',
    },
    cufv2: {
      short: 'CloudUsageV2',
      long: 'Cloud Usage Framework Version 2',
    },
  };

  return (
    <>
      {complianceLoading ? (
        <Loader />
      ) : showErrMsg ? (
        <div className="col-12">
          <Alert
            message={errorMessage}
            alertClass="light-danger"
            onClose={() => setShowErrMsg(false)}
          />
        </div>
      ) : (
        !errorMessage && (
          <>
            {Object.keys(accountCompliance).length > 0 ? (
              <div className="tab-pane active" id="messages-v">
                <div className="accordion" id="accordion">
                  {accountCompliance.cloudUsageFrameworkV2 && (
                  <ComplianceAccordion
                    type={viewOptions.cufv2}
                    isOpen={activeCloudUsageV2}
                    data={accountCompliance.cloudUsageFrameworkV2}
                    onClick={(e) => collapseSection(e)}
                    defaultOpen="true"
                  />
                  )}
                  {accountCompliance.newCloudUsageFramework && (
                    <ComplianceAccordion
                      type={viewOptions.newCuf}
                      isOpen={activeNewCloudUsage}
                      data={accountCompliance.newCloudUsageFramework}
                      onClick={(e) => collapseSection(e)}
                    />
                  )}

                </div>
              </div>
            ) : (
              <Alert message="No virtual networks found." alertClass="info" />
            )}
          </>
        )
      )}
    </>
  );
};

export default AccountComplianceTab;
