import React, { useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import HeaderWithInfo from '../Components/Common/HeaderWithInfo';
import { Context as MenuContext } from '../Context/MenuContext';
import { Context } from '../Context/RequestsContext';
import { saveUserPageVisitHistory } from '../store/actions/peopleAction';
import { Context as PageContext } from '../Context/PageContext';
import SubscriptionAccessRequest from '../Components/AzureSubscription/SubscriptionAccessRequest';

const SubscriptionAccessRequests = (props) => {
  const { user } = props;

  // Get filter data from Request Context
  const [modelPopup, setModelPopup] = useContext(Context);
  const { showSubscriptionAccessReqModal } = modelPopup;

  // Get selectedMenu data from Menu Context
  const [, setSelectedMenu, , setSelectedSubMenu] = useContext(MenuContext);
  const [pageContextState, setPageContextState] = useContext(PageContext);
  const { subscriptionPage } = pageContextState;

  const dispatch = useDispatch();

  // Get user login details
  useEffect(() => {
    if (subscriptionPage === '') {
      setPageContextState({
        ...pageContextState,
        subscriptionPage: 'subscriptions',
      });
      const apiUrl = `/api/Auth/SaveUserPageVisit/${user.employeeId}/subscriptions`;
      dispatch(saveUserPageVisitHistory(apiUrl));
    }
  }, [subscriptionPage]);

  useEffect(() => {
    setSelectedMenu('Azure');
    setSelectedSubMenu('subscriptionAccessRequests');
  }, []);

  const onSubscriptionAccessReqModal = (newValue) => {
    setModelPopup({ ...modelPopup, showSubscriptionAccessReqModal: newValue });
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          {/* <!-- Begin::Page Content --> */}
          <div className="card card-custom mb-4">
            <div className="card-header align-items-center">
              <div className="card-title align-items-center justify-content-between w-100">
                <HeaderWithInfo headerName="Subscriptions Access Request" infoIcon />
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={onSubscriptionAccessReqModal}
                >
                  <span className="svg-icon svg-light">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      // eslint-disable-next-line react/no-unknown-property
                      t="1551322312294"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      height="1.2em"
                      width="1.2em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <defs />
                      <path d="M474 152m8 0l60 0q8 0 8 8l0 704q0 8-8 8l-60 0q-8 0-8-8l0-704q0-8 8-8Z" />
                      <path d="M168 474m8 0l672 0q8 0 8 8l0 60q0 8-8 8l-672 0q-8 0-8-8l0-60q0-8 8-8Z" />
                    </svg>
                  </span>
                  {' '}
                  Request Access to Subscription
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showSubscriptionAccessReqModal && (
        <>
          <div className="log-out-layer" />
          <SubscriptionAccessRequest
            label="Request Subscription Access"
            onCancel={() => setModelPopup(false)}
            title="Request Access to an Azure Subscription"
            user={user}
          />
        </>
      )}
    </>
  );
};

export default SubscriptionAccessRequests;
