import React, {
  useState,
  useEffect,
  useRef,
  useContext,
} from 'react';
import { Link } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faAws } from '@fortawesome/free-brands-svg-icons';
import { useMediaQuery } from 'react-responsive';
import { FaChevronRight } from 'react-icons/fa';
import Logo from '../../Images/logo.png';
import { Context } from '../../Context/MenuContext';
import './SideNavigator.css';

const SideNavigator = ({ user }) => {
  const { isAdmin } = user || {};
  const [adminSubOpen, setAdminSubOpen] = useState(false);
  const [vendSubOpen, setVendSubOpen] = useState(false);
  const [ascenSubOpen, setAscenSubOpen] = useState(false);
  const [azureSubOpen, setAzureSubOpen] = useState(false);
  const [toggleSideNav, setToggleSideNav] = useState(true);
  const subMenuRef = useRef(null);
  const mobileMenuRef = useRef(null);
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });

  // Get selected menu from Menu Context
  const [selectedMenu, setSelectedMenu, selectedSubMenu, setSelectedSubMenu] = useContext(Context);

  // Handles styling on menu selected and closing submenus when other menu are selected
  function handleNavToggle(menuNm) {
    switch (menuNm) {
      case 'admin':
        setAdminSubOpen(!adminSubOpen);
        setAzureSubOpen(false);
        setAscenSubOpen(false);
        setVendSubOpen(false);
        if (adminSubOpen) { setToggleSideNav(true); }
        break;
      case 'Azure':
        setAzureSubOpen(!azureSubOpen);
        setAdminSubOpen(false);
        setAscenSubOpen(false);
        setVendSubOpen(false);
        if (azureSubOpen) { setToggleSideNav(true); }
        break;
      case 'cost report':
        setAscenSubOpen(!ascenSubOpen);
        setAdminSubOpen(false);
        setAzureSubOpen(false);
        setVendSubOpen(false);
        if (ascenSubOpen) { setToggleSideNav(true); }
        break;
      case 'vendor':
        setVendSubOpen(!vendSubOpen);
        setAdminSubOpen(false);
        setAscenSubOpen(false);
        setAzureSubOpen(false);
        if (vendSubOpen) { setToggleSideNav(true); }
        break;
      default:
        setAdminSubOpen(false);
        setAscenSubOpen(false);
        setVendSubOpen(false);
        setAzureSubOpen(false);
        setToggleSideNav(true);
        setSelectedSubMenu('');
    }
  }

  // Close SubMenu when clicked outside the subMenu
  useEffect(() => {
    function handleClickOutside(event) {
      if (subMenuRef.current && !subMenuRef.current.contains(event.target)) {
        setAdminSubOpen(false);
        setAzureSubOpen(false);
        setAscenSubOpen(false);
        setVendSubOpen(false);
      }
      if ((subMenuRef.current && !subMenuRef.current.contains(event.target))
        && (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target))) {
        setToggleSideNav(true);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      {/* <!-- Sidebar Toggle (Topbar Visible Only XS) --> */}
      <div className="sidebarToggleClass">
        <button
          type="button"
          id="sidebarToggleTop"
          className="btn btn-link d-md-none rounded-circle mr-3"
          onClick={() => setToggleSideNav(!toggleSideNav)}
          ref={mobileMenuRef}
        >
          <FontAwesomeIcon icon={faBars} />
        </button>
      </div>

      <nav
        ref={subMenuRef}
        className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ${toggleSideNav || isDesktop ? 'toggled' : ''}`}
        id="accordionSidebar"
      >
        {/* <!-- Sidebar - Logo --> */}
        <Link
          className="sidebar-brand d-flex align-items-center justify-content-center"
          to="/"
          onClick={() => { handleNavToggle('home'); setSelectedMenu('home'); }}
        >
          <img
            src={Logo}
            alt="csgLogo"
            className="img-fluid"
            style={{ height: '2.50rem' }}
            width="100"
          />
        </Link>

        {/* <!-- Divider --> */}
        <div className="mb-5" />

        <div className="sidebar-wrapper">

          {/* <!-- Nav Item - Home --> */}
          <Link
            to="/"
            className={`nav-item ${selectedMenu === 'home' ? 'active' : ''}`}
            onClick={() => handleNavToggle('home')}
          >
            <div
              className="nav-link"
              style={{
                backgroundColor: selectedMenu === 'home' ? '#eef0f8' : '',
              }}
            >
              <span className="svg-icon svg-icon-primary svg-icon-2x">
                {/* <!--begin::Svg Icon --> */}
                <svg
                  stroke="currentColor"
                  fill={selectedMenu === 'home' ? '#3699ff' : '#494b74'}
                  strokeWidth="0"
                  height="1em"
                  width="1em"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M16 9l-3-3V2h-2v2L8 1 0 9h2l1 5c0 .55.45 1 1 1h8c.55 0 1-.45 1-1l1-5h2zm-4 5H9v-4H7v4H4L2.81 7.69 8 2.5l5.19 5.19L12 14z"
                  />
                </svg>
                {/* <!--end::Svg Icon--> */}
              </span>
              <span>Home</span>
            </div>
          </Link>

          {/* <!-- Nav Item - Admin Collapse Menu --> */}
          {isAdmin && (
            <div
              className={`nav-item ${selectedMenu === 'admin' || adminSubOpen ? 'active' : ''}`}
              style={{ cursor: 'pointer' }}
              onClick={() => handleNavToggle('admin')}
            >
              <div
                className={`nav-link ${selectedMenu === 'admin' ? '' : 'collapsed'}`}
                data-toggle="collapse"
                data-target="#collapsePages2"
                aria-controls="collapsePages2"
                style={{
                  backgroundColor:
                    selectedMenu === 'admin' ? '#eef0f8' : '',
                }}
              >
                <span className="svg-icon svg-icon-primary svg-icon-2x">
                  {/* <!--begin::Svg Icon --> */}
                  <svg
                    stroke="currentColor"
                    fill={selectedMenu === 'admin' ? '#3699ff' : '#494b74'}
                    strokeWidth="0"
                    height="1em"
                    width="1em"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path fillRule="evenodd" d="M5.443 1.991a60.17 60.17 0 00-2.725.802.454.454 0 00-.315.366C1.87 7.056 3.1 9.9 4.567 11.773c.736.94 1.533 1.636 2.197 2.093.333.228.626.394.857.5.116.053.21.089.282.11A.73.73 0 008 14.5c.007-.001.038-.005.097-.023.072-.022.166-.058.282-.111.23-.106.525-.272.857-.5a10.197 10.197 0 002.197-2.093C12.9 9.9 14.13 7.056 13.597 3.159a.454.454 0 00-.315-.366c-.626-.2-1.682-.526-2.725-.802C9.491 1.71 8.51 1.5 8 1.5c-.51 0-1.49.21-2.557.491zm-.256-.966C6.23.749 7.337.5 8 .5c.662 0 1.77.249 2.813.525a61.09 61.09 0 012.772.815c.528.168.926.623 1.003 1.184.573 4.197-.756 7.307-2.367 9.365a11.191 11.191 0 01-2.418 2.3 6.942 6.942 0 01-1.007.586c-.27.124-.558.225-.796.225s-.526-.101-.796-.225a6.908 6.908 0 01-1.007-.586 11.192 11.192 0 01-2.417-2.3C2.167 10.331.839 7.221 1.412 3.024A1.454 1.454 0 012.415 1.84a61.11 61.11 0 012.772-.815z" clipRule="evenodd" />
                    <path d="M9.5 6.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
                    <path d="M7.411 8.034a.5.5 0 01.493-.417h.156a.5.5 0 01.492.414l.347 2a.5.5 0 01-.493.585h-.835a.5.5 0 01-.493-.582l.333-2z" />
                  </svg>

                  {/* <!--end::Svg Icon--> */}
                </span>
                <span>Admin</span>
              </div>
              <div
                id="collapsePages2"
                className={`collapse ${adminSubOpen ? 'show' : ''}`}
                aria-labelledby="headingPages"
                data-parent="#accordionSidebar"
              >
                <div className="py-2 collapse-inner">
                  <h4 className="collapse-header">Admin</h4>
                  <div className={`collapse-item sub-menu ${selectedSubMenu === 'roles' || selectedSubMenu === 'requests' ? 'subMenu' : ''} `}>
                    <div className="d-flex justify-content-between align-items-center">
                      Azure
                      <FaChevronRight />
                    </div>
                    <div className="sub-menu-list mt-3">
                      <Link className={`collapse-item ${selectedSubMenu === 'roles' ? 'subMenu' : ''}`} to="/roles">
                        Roles
                      </Link>
                      <Link className={`collapse-item ${selectedSubMenu === 'azuresubscriptioneditrequests' ? 'subMenu' : ''}`} to="azurerequests">
                        Requests Update
                      </Link>
                    </div>
                  </div>
                  <div className={`collapse-item sub-menu ${selectedSubMenu === 'roles' || selectedSubMenu === 'requests' || selectedSubMenu === 'cloudguru' || selectedSubMenu === 'awsserviceanalytics' ? 'subMenu' : ''} `}>
                    <div className="d-flex justify-content-between align-items-center">
                      AWS
                      <FaChevronRight />
                    </div>
                    <div className="sub-menu-list mt-3">
                      <Link className={`collapse-item ${selectedSubMenu === 'roles' ? 'subMenu' : ''}`} to="/roles">
                        Roles
                      </Link>
                      <Link className={`collapse-item ${selectedSubMenu === 'requests' ? 'subMenu' : ''}`} to="/requests">
                        Requests
                      </Link>
                      <Link className={`collapse-item ${selectedSubMenu === 'cloudguru' ? 'subMenu' : ''}`} to="/cloudguru">
                        Cloud Guru Users
                      </Link>
                      <Link className={`collapse-item ${selectedSubMenu === 'awsserviceanalytics' ? 'subMenu' : ''}`} to="/awsserviceanalytics">
                        AWS Service Analytics
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* <!-- Nav Item - AWS --> */}
          <Link
            to="/accounts"
            className={`nav-item ${selectedMenu === 'accounts' ? 'active' : ''}`}
            onClick={() => handleNavToggle('accounts')}
          >
            <div
              className="nav-link"
              style={{
                backgroundColor: selectedMenu === 'accounts' ? '#eef0f8' : '',
              }}
            >
              <span className="svg-icon svg-icon-primary svg-icon-2x">
                <FontAwesomeIcon icon={faAws} />
              </span>
              <span>AWS</span>
            </div>
          </Link>

          {/* <!-- Nav Item - Azure Subscription and Approval--> */}
          <div
            className={`nav-item ${selectedMenu === 'Azure' || azureSubOpen ? 'active' : ''}`}
            style={{ cursor: 'pointer' }}
            onClick={() => handleNavToggle('Azure')}
          >
            <div
              className={`nav-link ${selectedMenu === 'Azure' ? '' : 'collapsed'}`}
              data-toggle="collapse"
              data-target="#collapsePages3"
              aria-controls="collapsePages3"
              style={{
                backgroundColor:
                  selectedMenu === 'Azure' ? '#eef0f8' : '',
              }}
            >
              <span className="svg-icon svg-icon-primary">
                {/* <!--begin::Svg Icon --> */}
                <svg height="2.4em" width="2.4em" viewBox="0 0 59.242 47.271" xmlns="http://www.w3.org/2000/svg"><path d="m32.368 0-17.468 15.145-14.9 26.75h13.437zm2.323 3.543-7.454 21.008 14.291 17.956-27.728 4.764h45.442z" fill="#0072c6" /></svg>
                {/* <!--end::Svg Icon--> */}
              </span>
              <span>Azure</span>
            </div>
            <div
              id="collapsePages3"
              className={`collapse ${azureSubOpen ? 'show' : ''}`}
              aria-labelledby="headingPages"
              data-parent="#accordionSidebar"
            >
              <div className="py-2 collapse-inner">
                <h4 className="collapse-header">Azure Subscription</h4>
                <Link className={`collapse-item ${selectedSubMenu === 'subscriptionSearch' ? 'subMenu' : ''}`} to="/subscriptions">
                  Request & Search Subscriptions
                </Link>
                <Link className={`collapse-item ${selectedSubMenu === 'subscriptionRequests' ? 'subMenu' : ''}`} to="/subscriptionrequests">
                  Subscription Approvals
                </Link>
                <Link className={`collapse-item ${selectedSubMenu === 'trainingOverride' ? 'subMenu' : ''}`} to="azure/trainingoverride">
                  Azure Training Override
                </Link>
                <Link className={`collapse-item ${selectedSubMenu === 'subscriptionAccessRequests' ? 'subMenu' : ''}`} to="/subscriptionaccessrequests">
                  Subscription Access Request
                </Link>
              </div>
            </div>
          </div>

          {/* <!-- Nav Item - Networks --> */}
          <Link
            to="/virtualnetworks"
            className={`nav-item ${selectedMenu === 'networks' ? 'active' : ''}`}
            onClick={() => handleNavToggle('networks')}
          >
            <div
              className="nav-link"
              style={{
                backgroundColor: selectedMenu === 'networks' ? '#eef0f8' : '',
              }}
            >
              <span className="svg-icon svg-icon-primary">
                {/* <!--begin::Svg Icon |--> */}
                <svg
                  stroke="currentColor"
                  fill={selectedMenu === 'networks' ? '#3699ff' : '#494b74'}
                  strokeWidth="0"
                  height="2.4em"
                  width="2.4em"
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M377.4 32c-38.9 0-70.6 31.7-70.6 70.7 0 17.1 6.2 33.7 17.5 46.6 10 11.5 23.5 19.4 38.2 22.5v43.1L256 270.3l-106.4-55.4v-43.1c32.2-6.9 55.7-35.6 55.7-69.1 0-39-31.7-70.7-70.6-70.7S64 63.7 64 102.7c0 17.1 6.2 33.7 17.5 46.6 10 11.5 23.5 19.4 38.2 22.5v57.4c0 2.7 1.5 5.1 3.9 6.3l117.5 60.8v43.9c-32.2 6.9-55.7 35.6-55.7 69.1 0 39 31.7 70.7 70.6 70.7s70.6-31.7 70.6-70.7c0-17.1-6.2-33.7-17.5-46.6-10-11.5-23.5-19.4-38.2-22.5v-43.9l117.5-60.8c2.4-1.2 3.9-3.6 3.9-6.3v-57.4c32.2-6.9 55.7-35.6 55.7-69.1 0-39-31.7-70.7-70.6-70.7zM93.8 102.7c0-22.5 18.3-40.8 40.8-40.8s40.8 18.3 40.8 40.8c0 22.5-18.3 40.8-40.8 40.8s-40.8-18.3-40.8-40.8zm203 306.6c0 22.5-18.3 40.8-40.8 40.8s-40.8-18.3-40.8-40.8c0-22.5 18.3-40.8 40.8-40.8s40.8 18.3 40.8 40.8zm80.6-265.7c-22.5 0-40.8-18.3-40.8-40.8 0-22.5 18.3-40.8 40.8-40.8 22.5 0 40.8 18.3 40.8 40.8 0 22.4-18.3 40.8-40.8 40.8z" />
                </svg>
                {/* <!--end::Svg Icon--> */}
              </span>
              <span>Networks</span>
            </div>
          </Link>

          {/* <!-- Nav Item - People --> */}
          <Link
            to="/people"
            className={`nav-item ${selectedMenu === 'people' ? 'active' : ''}`}
            onClick={() => handleNavToggle('people')}
          >
            <div
              className="nav-link"
              style={{
                backgroundColor: selectedMenu === 'people' ? '#eef0f8' : '',
              }}
            >
              <span className="svg-icon svg-icon-primary">
                {/* <!--begin::Svg Icon --> */}
                <svg
                  stroke="currentColor"
                  fill={selectedMenu === 'people' ? '#3699ff' : '#494b74'}
                  strokeWidth="0"
                  height="2.2em"
                  width="2.2em"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.995-.944v-.002.002zM7.022 13h7.956a.274.274 0 00.014-.002l.008-.002c-.002-.264-.167-1.03-.76-1.72C13.688 10.629 12.718 10 11 10c-1.717 0-2.687.63-3.24 1.276-.593.69-.759 1.457-.76 1.72a1.05 1.05 0 00.022.004zm7.973.056v-.002.002zM11 7a2 2 0 100-4 2 2 0 000 4zm3-2a3 3 0 11-6 0 3 3 0 016 0zM6.936 9.28a5.88 5.88 0 00-1.23-.247A7.35 7.35 0 005 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 015 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10c-1.668.02-2.615.64-3.16 1.276C1.163 11.97 1 12.739 1 13h3c0-1.045.323-2.086.92-3zM1.5 5.5a3 3 0 116 0 3 3 0 01-6 0zm3-2a2 2 0 100 4 2 2 0 000-4z"
                    clipRule="evenodd"
                  />
                </svg>
                {/* <!--end::Svg Icon--> */}
              </span>
              <span>People</span>
            </div>
          </Link>

          {/* <!-- Nav Item - Education --> */}
          <Link
            to="education"
            className={`nav-item ${selectedMenu === 'education' ? 'active' : ''}`}
            onClick={() => handleNavToggle('education')}
          >
            <div
              className="nav-link"
              style={{
                backgroundColor: selectedMenu === 'education' ? '#eef0f8' : '',
              }}
            >
              <span className="svg-icon svg-icon-primary">
                {/* <!--begin::Svg Icon --> */}
                <svg
                  version="1.1"
                  stroke="currentColor"
                  fill={selectedMenu === 'education' ? '#3699ff' : '#494b74'}
                  strokeWidth="0"
                  height="2.3em"
                  width="2.3em"
                  id="lni_lni-graduation"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 64 64"
                  xmlSpace="preserve"
                >
                  <path
                    d="M62.1,34.5l-4.5-4.6v-4.4c0.7-0.6,1-1.5,1-2.4c0-1.3-0.7-2.4-1.9-3L31.4,8.6c-0.8-0.4-1.8-0.4-2.6,0L3.2,19.9
                  c-1.2,0.5-1.9,1.7-1.9,3s0.7,2.5,1.9,3l9,4.1v12.6c0,7.2,5.8,13.1,13,13.1h9.4c7.2,0,13-5.9,13-13.1V30.2l5.3-2.4l1.1-0.5v2.8
                  l-4.3,4.4c-0.9,0.9-0.9,2.4,0,3.3l4.5,4.6c0.4,0.4,1,0.7,1.6,0.7c0.6,0,1.2-0.3,1.6-0.7l4.5-4.6C63,36.8,63,35.4,62.1,34.5z
                  M44.2,42.5c0,5.3-4.3,9.6-9.5,9.6h-9.4c-5.3,0-9.5-4.3-9.5-9.6v-11l13.2,5.9c0.4,0.2,0.9,0.3,1.3,0.3c0.4,0,0.9-0.1,1.3-0.3
                  l12.6-5.7V42.5z M51.5,24.6l-21.3,9.6L5.1,22.9l25-11l24.8,11.2L51.5,24.6z M55.9,39l-2.8-2.9l2.8-2.9l2.8,2.9L55.9,39z"
                  />
                </svg>

                {/* <!--end::Svg Icon--> */}
              </span>
              <span>Education</span>
            </div>
          </Link>

          {/* <!-- Nav Item - Cost Report Collapse Menu --> */}
          <div
            className={`nav-item ${selectedMenu === 'cost report' || ascenSubOpen ? 'active' : ''}`}
            style={{ cursor: 'pointer' }}
            onClick={() => handleNavToggle('cost report')}
          >
            <div
              className={`nav-link ${selectedMenu === 'cost report' ? '' : 'collapsed'}`}
              data-toggle="collapse"
              data-target="#collapsePages1"
              aria-controls="collapsePages1"
              style={{
                backgroundColor:
                  selectedMenu === 'cost report' ? '#eef0f8' : '',
              }}
            >
              <span className="svg-icon svg-icon-primary svg-icon-2x">
                {/* <!--begin::Svg Icon --> */}
                <svg
                  stroke="currentColor"
                  fill={selectedMenu === 'cost report' ? '#3699ff' : '#494b74'}
                  strokeWidth="0"
                  version="1.1"
                  id="lni_lni-bar-chart"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  width="24px"
                  height="24px"
                  viewBox="0 0 64 64"
                  xmlSpace="preserve"
                >
                  <path
                    d="M61,56.6h-6.2v-21c0-3-2.4-5.4-5.4-5.4h-6c-3,0-5.4,2.4-5.4,5.4v21h-7.4V20c0-3-2.4-5.4-5.4-5.4h-6c-3,0-5.4,2.4-5.4,5.4
                  v36.6H6.6c-1,0-1.9-0.8-1.9-1.9V5.6c0-1-0.8-1.8-1.8-1.8S1.3,4.7,1.3,5.6v49.1c0,3,2.4,5.4,5.4,5.4h7.5h16.7h7.4h16.7H61
                  c1,0,1.8-0.8,1.8-1.8S62,56.6,61,56.6z M17.6,56.6V20c0-1,0.8-1.9,1.9-1.9h6c1,0,1.9,0.8,1.9,1.9v36.6H17.6z M41.7,56.6v-21
                  c0-1,0.8-1.9,1.9-1.9h6c1,0,1.9,0.8,1.9,1.9v21H41.7z"
                  />
                </svg>
                {/* <!--end::Svg Icon--> */}
              </span>
              <span>Cost Report</span>
            </div>
            <div
              id="collapsePages1"
              className={`collapse ${ascenSubOpen ? 'show' : ''}`}
              aria-labelledby="headingPages"
              data-parent="#accordionSidebar"
            >
              <div className="py-2 collapse-inner">
                <h4 className="collapse-header">Cost Report</h4>
                <Link className={`collapse-item ${selectedSubMenu === 'cloudabilityCosting' ? 'subMenu' : ''}`} to="/cloudabilitycosting">
                  Ascendon Cloudability Costing
                </Link>
                <Link className={`collapse-item ${selectedSubMenu === 'apiStats' ? 'subMenu' : ''}`} to="/ascendonapistats">
                  Ascendon API Stats
                </Link>
                <Link className={`collapse-item ${selectedSubMenu === 'awsservicedashboard' ? 'subMenu' : ''}`} to="/awsservicedashboard">
                  Enterprise AWS Service Costing
                </Link>
              </div>
            </div>
          </div>

          <div
            className={`nav-item ${selectedMenu === 'vendor' || vendSubOpen ? 'active' : ''}`}
            style={{ cursor: 'pointer' }}
            onClick={() => handleNavToggle('vendor')}
          >
            <div
              className={`nav-link ${selectedMenu === 'vendor' ? '' : 'collapsed'}`}
              data-toggle="collapse"
              data-target="#collapsePages"
              aria-controls="collapsePages"
              style={{
                backgroundColor:
                  selectedMenu === 'vendor' ? '#eef0f8' : '',
              }}
            >
              <span className="svg-icon svg-icon-primary">
                {/* <!--begin::Svg Icon --> */}
                <svg
                  stroke="currentColor"
                  fill={selectedMenu === 'vendor' ? '#3699ff' : '#494b74'}
                  strokeWidth="0"
                  width="1.6rem"
                  height="1.6rem"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M14 1H2a1 1 0 00-1 1v12a1 1 0 001 1h12a1 1 0 001-1V2a1 1 0 00-1-1zM2 0a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V2a2 2 0 00-2-2H2z"
                    clipRule="evenodd"
                  />
                  <path
                    d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z"
                  />
                  <circle cx="8" cy="4.5" r="1" />
                </svg>
                {/* <!--end::Svg Icon--> */}
              </span>
              <span>Vendor Information</span>
            </div>
            <div
              id="collapsePages"
              className={`collapse ${vendSubOpen ? 'show   ' : ''}`}
              aria-labelledby="headingPages"
              data-parent="#accordionSidebar"
            >
              <div className="py-2 collapse-inner">
                <h4 className="collapse-header">Vendor Information</h4>
                <Link className={`collapse-item ${selectedSubMenu === 'awsHealthEvent' ? 'subMenu' : ''}`} to="/awshealthevents">
                  AWS Health Event
                </Link>
                <Link className={`collapse-item ${selectedSubMenu === 'awsServices' ? 'subMenu' : ''}`} to="/services">
                  AWS Services
                </Link>
                <Link className={`collapse-item ${selectedSubMenu === 'productAnnouncements' ? 'subMenu' : ''}`} to="/productannouncements">
                  Product Announcements
                </Link>
                <Link className={`collapse-item ${selectedSubMenu === 'postEventSummaries' ? 'subMenu' : ''}`} to="/posteventsummaries">
                  Post-Event Summaries
                </Link>
                <Link className={`collapse-item ${selectedSubMenu === 'cloudStockWidget' ? 'subMenu' : ''}`} to="/stockwidget">
                  Cloud Stock Widget
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default SideNavigator;
